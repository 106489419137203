.marginT {
    margin-top: 10px;
}
.mine_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    border-top: solid 1px #F2F2F2;
    
    &:first-child {
        border:none
    }
    .mine_item_name {
        padding: 0 20px;
        flex: 1;
        font-size: 14px;
    }
    .mine_policy_itme_name {
        flex: 1;
        font-size: 14px;
    }
}