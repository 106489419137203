.list_collaps_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.list_collaps_cont {
    background-color: #f5f5f5;
    padding: 14px;
    font-size: 13px;
    .flexCenter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .cont_item {
        padding: 4px;
    }
}
.color_green {
    color: #10800c;
}
.import_font {
    border-width: 600;
}
.l1 {
    width: 70px;
}
.l2 {
    flex: 1
}