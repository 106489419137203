html {
  height: 100%;
}
body {
  height: 100%;
  background-color: #f2f2f2;
}

#root {
  height: 100%;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* color */
.red_color {
  color: #fb4034;
}
.blue_color {
  color: #1677ff;
}

.light_grey_color {
  color: #888
}

.box_layer {
  width: 96%;
  margin: 0 auto;
}
.flexBetweenCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}