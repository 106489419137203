.main_icon {
    display: flex;
    padding: 20px;
    background-color: #FFF;
    margin-top: 6px;
    margin-bottom: 10px;
}

.main_icon_item {
    width: 30%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 24px;
    }
}
.marginT {
    margin-top: 10px;
}


/* social */
.social_box {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px 20px;
    margin-top: 6px;
    .social_title {
        border-bottom: solid 2px green;
        padding-bottom: 12px;
    }
    .adm-checkbox .adm-checkbox-icon {
        border-radius: 4px;
    }
    
}

.adm-list-item-content {
    font-size: 14px;
}
.adm-form-item-horizontal{
    .adm-list-item-content-main {
        display: flex;
        justify-content: flex-end;
    }
}

.icons_layer {
    display: flex;
    justify-content: space-between;
}


.noticeList {
    padding: 0;
    margin: 0;
    padding-left: 20px;
    li {
        padding: 0;
        margin: 0;
        list-style-type: none;
        padding: 4px 0 10px;
    }
}

.base_input_box {
    display: flex;
    flex-direction: row;
    align-items: center;
}



// 
.confirm_info_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.confirm_button {
    margin: 10px auto 0;
    flex: 1;
    width: 96%;
    display: flex;
    justify-content: center;
}

.wxpay_line {
    padding: 20px 12px;
}
.pay_box {
    width: 100%;
    height: 55px;
    position: fixed;
    bottom: 0;
    background-color: #FFF;
    display: flex;
    align-items: center;
    .amount {
        flex: 1;
        text-align: right;
        padding-right: 20px;
        font-size: 16px;
        font-weight: bold;
    }
    .pay_button {
        width: 120px;
        height: 55px;
        background-color: #00b578;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}