.add-perosn-box {
    height: '220px';
    background: '#f2f2f2';
    display: 'flex';
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.add_box {
    padding-top: 20px;
    width: 80vw;
    .add_title {
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }
    .adm-input-element {
        font-size: 13px;
    }
}