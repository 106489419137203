.button {
    width: 100%
}
.pay_box {
    width: 100%;
    height: 55px;
    position: fixed;
    bottom: 0;
    background-color: #FFF;
    display: flex;
    align-items: center;
    .amount {
        flex: 1;
        text-align: right;
        padding-right: 20px;
        font-size: 16px;
        font-weight: bold;
    }
    .pay_button {
        width: 120px;
        height: 55px;
        background-color: #00b578;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.detail_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.detail_cont {
    background-color: #ebf1f3;
    border-top: 1px solid #cddde2;
    border-bottom: 1px solid #cddde2;
    padding: 8px;
    .detail_cont_item {
        padding: 4px 0;
    }
    .month_layer {
        width: 90px;
    }
}
.wx_line {
    padding: 15px 12px;
}
.soical_form {
    .adm-card-body {
        .adm-form {
            --border-top: none;
        }
    }
    .adm-radio-content {
        font-size: 14px;
    }
    overflow-y: auto;
}
