
.policy_page_box {
    background-color: #FFF;
    height: 100%;
    .big_title {
        font-size: 26px;
        // font-weight: bold;
        margin: 0 auto;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .policy_img {
        margin: 0 auto;
    }

    .policy_con {
        padding: 0 40px;
        line-height: 24px;
        font-size: 14px;
        .policy_title {
            font-size: 18px;
            font-weight: 700;
        }
        .suojin {
            text-indent: 28px;
        }
    }
    .detail_title {
        padding-top: 30px;
        padding-bottom:10px;
        padding-left: 30px;
        font-size: 14px;
    }
}