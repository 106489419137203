.box {
    background: #FFF;
    height: 100%;
    padding: 0px 20px 20px 20px;
    flex: 1;
    margin-top: 10px;
}
.noticeItem {
    // border-top: 8px solid #F2F2F2;
    padding-top: 20px;
    p {
        line-height: 24px;
        padding-bottom: 12px;
    }
}
.titleBox {
    display:flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
}
.mt40 {
    margin-top: 20px;
}
.import {
    font-weight: bold;
}
.space_line {
    border-top: 2px solid #F2F2F2;
}
.dot {
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 3px;
    background-color: #666666;
}