.footerBox {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: #dbdbdb;

    color: #333;
    height: 75px;
    font-size: 14px;

}
.footerItem {
    width: 25%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    height: 75px;
    p {
        padding: 0;
        margin: 0
    }
}

.activeItem {
    background-color: #b6b5b5;
    color: #e43939;
    font-weight: bold;
}