/* 政策 */
.part_2{
    margin: 10px 0;
  }
.licence_wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
  }
  .licence_item{
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    width: 43%;
    margin-bottom: 10px;
  }
  .licence_txt_wrap{
    padding-right: 5px;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
  }
  .licence_item:nth-child(odd){
    margin-right: 4px;
  }
  .licence_item:nth-child(even){
    margin-left: 4px;
  }
