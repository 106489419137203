.marginT {
    margin-top: 10px;
}
.person_item {
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 12px;
    .person_item_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p{
            margin: 6px 0
        }
        p:first-child{
            font-weight: bold;
            font-size: 14px;
        }
    }
}
.btn_wrap{
    display: flex;
    justify-content: flex-end;
    button{
        margin-left: 4px;
    }
}
.add_person_button {
    width: 100%;
    margin-top: 10px;
}

.addPersonForm {
    .adm-list-item-content-main{
        flex-direction: column;
        .adm-list-item-description {
            display: flex;
            justify-content: flex-end;
        }
    }
}
