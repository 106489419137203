.successTopBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #0fa553;
    padding-top: 80px;
}
.successMiddleBox {
    display: flex;
    flex-direction:column;
    align-items: center;
    padding-top: 180px;
    font-size: 16px;
    .successMiddleBox_price_box {
        font-size: 20px;
        margin-top: 20px;
        .successMiddleBox_price {
            font-size: 30px;
            font-weight: bold;
        }
    }
   
}