/*  */
.icons_layer {
    background-color: #FFF;
    margin-top: 6px;
    display: flex;
    /* justify-content: space-between; */
    padding: 20px 30px
}

.beianhao {
    width: 240px;
    border-right: solid 1px #999;
    // margin-right: 10px;
    padding-right: 20px;
    .beianhao_top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    p {
        margin: 0;
        padding: 6px 0;
    }

}
.wei_icon {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}