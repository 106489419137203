
.App {
  text-align: center;
  background-color: #F5F5F5;
}

.header-wrap{
  background-color: aquamarine;
  height: 200px;
  padding: 15px;
}
.top{
  display: flex;
  justify-content: space-between;
}
.bottom{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.user_name{
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// .part_1{
//   margin: 10px 0;
//   display: flex;
//   flex-wrap: wrap;
// }

// .item_block{
//   width: 33%;
// }
// .item_block_font{
//   font-size: 14px;
//   padding: 10px;
//   p{
//     margin: 0;
//     padding: 0;
//     font-size: 14px;
//     line-height: 24px;
//   }
// }

.loading_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0,0,0,0.3);
  .loading_img_box {
    position: relative;
    z-index: 101;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    margin-top: 340px;
  }
}

