.part_1{
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .item_block{
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .item_block_font{
    font-size: 14px;
    padding: 10px;
    p{
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 24px;
    }
  }
  