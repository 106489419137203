.headerBox {
    height: 140px;
    background: #00474f;
    border-radius: 6px;
    display: flex;
    padding: 10px 10px;
    .photo {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        
        border: solid 2px #FFF;
        overflow: hidden;
    }
    .phoneBox {
        display: flex;
        align-items: center;
        padding: 0 0 20px 0px;
    }
    .info {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }
    .name {
        font-size: 14px;
        color: #FFF;
        margin-left: 10px;
    }
    .headerBot_left {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content:space-between;
    }
}
.qw_logo_box {
    position: absolute;
    top: 95px;
    right: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 10px;
    background-color: rgba(0,35,41,0.4);
    padding: 9px 12px;
    border-radius: 8px;
    .logo_text {
        margin-left: 8px;
        color: #FFF;
        font-size: 14px;
    }
}
.qw_logo_box_new {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 10px;
    // background-color: rgba(0,35,41,0.4);
    // padding: 9px 12px;
    border-radius: 8px;
    .logo_text {
        margin-left: 8px;
        color: #FFF;
        font-size: 14px;
    }
}
.kefu_box {
    width: 320px;
    height: 190px;
    background-color: #e0dede;
    margin: 200px auto 0;
    padding-top: 30px;
    position: relative;
    .kefu_box_content {
        width: 90%;
        height: 70%;
        background-color: #FFF;
        border-radius: 8px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 40px;
        .kefu_box_content_left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
        }
        .kefu_box_content_right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
        }
        p {
            margin: 0;
            padding: 0;
            line-height: 22px;
        }
        .phone_line {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 4px;
        }
    }
    .kefu_logo_box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 34%;
        top: 10px;
        .logo{
            width: 40px;
            height: 40px;
            background-color: #92d6b3;
            border-radius: 20px;
            border: solid 3px #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}